export const ErrorCode = {
    AuthenticationFailed: 'AuthenticationFailed',
    AuthorizationFailed: 'AuthorizationFailed',
    LabAccountAuthorizationFailed: 'LabAccountAuthorizationFailed',
    LabAuthorizationFailed: 'LabAuthorizationFailed',
    EnvironmentSettingsHasNoResources: 'EnvironmentSettingsHasNoResources',
    GenericError: 'GenericError',
    InvalidPropertyLength: 'InvalidPropertyLength',
    InvalidUploadTargetToOverride: 'InvalidUploadTargetToOverride',
    LabFailed: 'LabFailed',
    MustBeGreaterThanProperty: 'MustBeGreaterThanProperty',
    NoSharedImageGalleriesAttachedToTheLabAccount: 'NoSharedImageGalleriesAttachedToTheLabAccount',
    NotFound: 'NotFound',
    OperationAlradyInProgress: 'OperationAlradyInProgress',
    ResourceDoesNotExist: 'ResourceDoesNotExist',
    ResourceIdNotFound: 'ResourceIdNotFound',
    ResourceInDeletingState: 'ResourceInDeletingState',
    ResourceIsNotInValidState: 'ResourceIsNotInValidState',
    ResourceNotFound: 'ResourceNotFound',
    ResourceGroupNotFound: 'ResourceGroupNotFound',
    ResourceNotInSucceededState: 'ResourceNotInSucceededState',
    ResourceNotReady: 'ResourceNotReady',
    ResourceInGroupDoesNotExist: 'ResourceInGroupDoesNotExist',
    SessionExpired: 'SessionExpired',
    IdleSessionExpired: 'IdleSessionExpired',
    SubscriptionNotEnabled: 'SubscriptionNotEnabled',
    TimeoutException: 'TimeoutException',
    UnexpectedResourceId: 'UnexpectedResourceId',
    LabCreationExceedsGpuCoresLimit: 'LabCreationExceedsGpuCoresLimit',
    LabCreationExceedsStandardCoresLimit: 'LabCreationExceedsStandardCoresLimit',
    PublishExceedsGpuCoresLimit: 'PublishExceedsGpuCoresLimit',
    PublishExceedsStandardCoresLimit: 'PublishExceedsStandardCoresLimit',
    LabCapacityUpdateExceedsGpuCoresLimit: 'LabCapacityUpdateExceedsGpuCoresLimit',
    LabCapacityUpdateExceedsStandardCoresLimit: 'LabCapacityUpdateExceedsStandardCoresLimit',
    PasswordNotValid: 'PasswordNotValid',
    PeerVnetAddressRangeOutOfAddresses: 'PeerVnetAddressRangeOutOfAddresses',
    VMAgentStatusCommunicationError: 'VMAgentStatusCommunicationError',
    InvalidGrant: 'InvalidGrant',
    InvalidGrantPopupBlocked: 'InvalidGrantPopupBlocked',
    PopupWindowBlocked: 'PopupWindowBlocked',
    LtiTokenExpired: 'LtiTokenExpired',
    CookiesAndDataBlocked: 'CookiesAndDataBlocked',
    LtiRequestStorageAccessNeeded: 'LtiRequestStorageAccessNeeded',
    // V2
    AllowedRegionsLimitExceeded: 'AllowedRegionsLimitExceeded',
    TotalRegionalCoreLimit: 'TotalRegionalCoreLimit',
    Arm64Incompatibility: 'Arm64Incompatibility',
    InvalidDiskSize: 'InvalidDiskSize',
    InvalidImagePlanInfo: 'InvalidImagePlanInfo',
    InvalidAdminUsername: 'InvalidAdminUsername',
    LabBeingDeleted: 'LabBeingDeleted',
    ImageUnavailable: 'ImageUnavailable',
    WebAccessVnetNotSupported: 'WebAccessVnetNotSupported',
    InvalidSubnet: 'InvalidSubnet',
    SaveImageRegionMismatch: 'SaveImageRegionMismatch',
    SaveImageAlreadyInProgress: 'SaveImageAlreadyInProgress',
    DiskSizeLesserThanExisting: 'DiskSizeLesserThanExisting',
    SubnetIsFull: 'SubnetIsFull',
};
export default ErrorCode;
