import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    RetirementNotificationPageTitle: {
        id: 'RetirementNotificationPageTitle',
        defaultMessage: 'Azure Lab Services Retirement Notification',
        description: 'The title text displayed on the page of the retirement notification card.',
    },
    RetirementNotificationPageContent: {
        id: 'RetirementNotificationPageContent',
        defaultMessage: 'Azure Lab Services will be retired on June 28th, 2027.',
        description: 'The text content on the page of the retirement notification card.',
    },
    RetirementNotificationPageExtraContent: {
        id: 'RetirementNotificationPageExtraContent',
        defaultMessage: 'Click here to learn more',
        description: 'The extra link text content on the page of the retirement notification card.',
    },
});
