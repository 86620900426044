import { defineMessages } from 'react-intl';
import { FailureOperation } from '../data/ml-client-error';
import { ErrorCode } from '../common/error-codes';
import commonMessages from '../language/common-messages';
import { trackTrace } from '../utils/telemetry/telemetry-channel';
export const messages = defineMessages({
    getTemplateErrorNotFound: {
        id: 'GetTemplateErrorNotFound',
        defaultMessage: `Unable to get the template because it could not be found.`,
        description: 'Message to indicate that fetching the template failed because it could not be found.',
    },
    getTemplateGenericError: {
        id: 'GetTemplateGenericError',
        defaultMessage: `Unable to get the template because an error has occurred.`,
        description: 'Message to indicate that fetching the template failed.',
    },
    updateTemplateErrorNotFound: {
        id: 'UpdateTemplateErrorNotFound',
        defaultMessage: `Unable to update the template because it could not be found.`,
        description: 'Message to indicate that updating the template failed because it could not be found.',
    },
    updateTemplateErrorResourceInDeletingState: {
        id: 'UpdateTemplateErrorResourceInDeletingState',
        defaultMessage: `Unable to update the template because it is deleting.`,
        description: 'Message to indicate that updating the template failed because it is deleting.',
    },
    updateTemplateGenericError: {
        id: 'UpdateTemplateGenericError',
        defaultMessage: `Unable to update the template because an error has occurred.`,
        description: 'Message to indicate that updating the template failed.',
    },
    stopTemplateErrorNotFound: {
        id: 'StopTemplateErrorNotFound',
        defaultMessage: `Unable to stop template because it could not be found.`,
        description: 'Message to indicate that stopping the template failed because it could not be found.',
    },
    stopTemplateErrorNoResources: {
        id: 'StopTemplateErrorNoResources',
        defaultMessage: `Unable to stop the template because there are no resources created for the template.`,
        description: 'Message to indicate that stopping the template failed because it did not have any backing resources.',
    },
    stopTemplateGenericError: {
        id: 'StopTemplateGenericError',
        defaultMessage: `Unable to stop the template because an error has occurred.`,
        description: 'Message to indicate that stopping the template failed.',
    },
    startTemplateErrorNotFound: {
        id: 'StartTemplateErrorNotFound',
        defaultMessage: `Unable to start the template because it could not be found.`,
        description: 'Message to indicate that starting the template failed because it could not be found.',
    },
    startTemplateErrorNoResources: {
        id: 'StartTemplateErrorNoResources',
        defaultMessage: `Unable to start the template because there are no resources created for the template.`,
        description: 'Message to indicate that starting the template failed because it did not have any backing resources.',
    },
    startTemplateGenericError: {
        id: 'StartTemplateGenericError',
        defaultMessage: `Unable to start the template because an error has occurred.`,
        description: 'Message to indicate that starting the template failed.',
    },
    resetPasswordTemplateErrorNotFound: {
        id: 'ResetPasswordTemplateErrorNotFound',
        defaultMessage: `Unable to reset the password because the template could not be found.`,
        description: 'Message to indicate that resetting the password failed because the template could not be found.',
    },
    resetPasswordTemplateErrorNoResources: {
        id: 'ResetPasswordTemplateErrorNoResources',
        defaultMessage: `Unable to reset the password because there are no resources created for the template.`,
        description: 'Message to indicate that resetting the password failed because the template did not have any backing resources.',
    },
    resetPasswordTemplateErrorResourceNotInSucceededState: {
        id: 'ResetPasswordTemplateErrorResourceNotInSucceededState',
        defaultMessage: `Unable to reset the password because the template is not in a succeeded state.`,
        description: 'Message to indicate that resetting the password failed because the template is not in a succeeded state.',
    },
    resetPasswordTemplateErrorResourceDoesNotExist: {
        id: 'ResetPasswordTemplateErrorResourceDoesNotExist',
        defaultMessage: `Unable to reset the password because the template resource does not exist.`,
        description: 'Message to indicate that resetting the password failed because the template resource does not exist.',
    },
    resetPasswordTemplateInvalidPassword: {
        id: 'ResetPasswordTemplateInvalidPassword',
        defaultMessage: `Unable to reset the password because it doesn't meet password policies.`,
        description: `Message to indicate that the provided password wasn't complex enough, long enough, or reused an older password.`,
    },
    resetPasswordVmAgentNoCommunicationError: {
        id: 'ResetPasswordVmAgentNoCommunicationError',
        defaultMessage: 'Communication could not be established with the VM agent. Please verify that the VM agent is enabled and functioning.',
        description: 'Occurs when the agent cannot be communicated with.',
    },
    resetPasswordTemplateGenericError: {
        id: 'ResetPasswordTemplateGenericError',
        defaultMessage: `Unable to reset the password because an error has occurred.`,
        description: 'Message to indicate that resetting the password failed.',
    },
    publishTemplateErrorNotFound: {
        id: 'PublishTemplateErrorNotFound',
        defaultMessage: `Unable to publish the template because it could not be found`,
        description: 'Message to indicate that publishing the template failed because the template could not be found.',
    },
    publishTemplateErrorNoResources: {
        id: 'PublishTemplateErrorNoResources',
        defaultMessage: `Unable to publish the template because there are no resources created for the template.`,
        description: 'Message to indicate that publishing the template failed because the template did not have any backing resources.',
    },
    publishTemplateErrorResourceNotInSucceededState: {
        id: 'PublishTemplateErrorResourceNotInSucceededState',
        defaultMessage: `Unable to publish the template because the template is not in a succeeded state.`,
        description: 'Message to indicate that publishing the template failed because the template is not in a succeeded state.',
    },
    publishTemplateErrorSubscriptionNotEnabled: {
        id: 'PublishTemplateErrorSubscriptionNotEnabled',
        defaultMessage: `Unable to publish the template because the subscription containing the template resource is not enabled.`,
        description: 'Message to indicate that publishing the template failed because the subscription is not enabled.',
    },
    publishTemplateErrorResourceNotReady: {
        id: 'PublishTemplateErrorResourceNotReady',
        defaultMessage: `Unable to publish the template because it is not in a succeeded state.`,
        description: 'Message to indicate that publishing the template failed because the template is not in a succeeded state.',
    },
    publishTemplateTotalRegionalCoreLimitError: {
        id: 'PublishTemplateTotalRegionalCoreLimitError',
        defaultMessage: `Publish operation could not be completed as it results in exceeding approved total regional core limits on your account. You need to {requestLimitIncreaseLink}. You can expect the process to take 1-5 days. Due to high demand, some virtual machine types may be temporarily unavailable.`,
        description: 'Message to indicate that publishing the template failed due to exceeding the total regional core limit.',
    },
    publishTemplateErrorDiskSizeLesserThanExisting: {
        id: 'PublishTemplateErrorDiskSizeLesserThanExisting',
        defaultMessage: `Unable to publish the template because the disk size is lesser than the existing disk size. This is not allowed. Please try a greater size or do not specify an explicit size.`,
        description: 'Message to indicate that publishing the template failed because the disk size is lesser than the existing disk size.',
    },
    publishTemplateGenericError: {
        id: 'PublishTemplateGenericError',
        defaultMessage: `Unable to publish the template because an error has occurred.`,
        description: 'Message to indicate that publishing the template failed.',
    },
    saveImageTemplateErrorNotFound: {
        id: 'SaveImageTemplateErrorNotFound',
        defaultMessage: `Unable to save the image because it could not be found.`,
        description: 'Message to indicate that saving the image failed because it could not be found.',
    },
    saveImageTemplateErrorInvalidUploadTargetToOverride: {
        id: 'SaveImageTemplateErrorInvalidUploadTargetToOverride',
        defaultMessage: `Unable to save the image because the wrong VM image was sent for upload.`,
        description: 'Message to indicate that saving the image failed because the wrong shared image id was sent.',
    },
    saveImageTemplateErrorResourceDoesNotExist: {
        id: 'SaveImageTemplateErrorResourceDoesNotExist',
        defaultMessage: `Unable to save the image because the image is either disabled or does not exist.`,
        description: 'Message to indicate that saving the image failed because the image is disabled or does not exist.',
    },
    saveImageTemplateErrorNoSharedImageGalleriesAttachedToTheLabAccount: {
        id: 'SaveImageTemplateErrorNoSharedImageGalleriesAttachedToTheLabAccount',
        defaultMessage: `Unable to save the image because no galleries are attached to the lab account or lab plan.`,
        description: 'Message to indicate that saving the image failed because no galleries are attached to the lab account or lab plan.',
    },
    saveImageTemplateErrorAlreadyInProgress: {
        id: 'SaveImageTemplateErrorAlreadyInProgress',
        defaultMessage: `Unable to save the image because the image is already being saved.`,
        description: 'Message to indicate that saving the image failed because there is already a save image operation in progress.',
    },
    saveImageTemplateErrorUnexpectedResourceId: {
        id: 'SaveImageTemplateErrorUnexpectedResourceId',
        defaultMessage: `Unable to save the image because there was a problem parsing its id.`,
        description: 'Message to indicate that saving the image failed because we encountered an unexpected resource id.',
    },
    saveImageTemplateErrorResourceNotInSucceededState: {
        id: 'SaveImageTemplateErrorResourceNotInSucceededState',
        defaultMessage: `Unable to save the image because the template is not in a succeeded state.`,
        description: 'Message to indicate that saving the image failed because the template is not in a succeeded state.',
    },
    saveImageTemplateErrorRegionMismatch: {
        id: 'SaveImageTemplateErrorRegionMismatch',
        defaultMessage: `Exporting to a gallery does not currently support region mismatches between the lab and the lab plan. Please deploy them in the same regions to use this feature.`,
        description: 'Text to indicate saving the image failed because the lab and lab plan are deployed in different regions.',
    },
    saveImageAlreadyInProgressError: {
        id: 'SaveImageAlreadyInProgressError',
        defaultMessage: `An image export to Azure compute gallery is already in progress with the given image name.`,
        description: 'Message to indicate that saving the image failed because the image already exists.',
    },
    saveImageTemplateGenericError: {
        id: 'SaveImageTemplateGenericError',
        defaultMessage: `Unable to save the image because an error has occurred.`,
        description: 'Message to indicate that saving the image failed.',
    },
    arm64IncompatibilityError: {
        id: 'Arm64IncompatibilityError',
        defaultMessage: 'Failed to create lab with image with CPU Architecture Arm64. Arm64 is not supported for Azure Lab Services VMs, please try creating lab with different image.',
        description: 'Message to indicate that creating a lab or publishing the template failed due to Arm64 incompatibility',
    },
    invalidDiskSizeError: {
        id: 'InvalidDiskSizeError',
        defaultMessage: 'The specified disk size is smaller than the size of the corresponding disk in the VM image. This is not allowed. Please choose equal or greater size or do not specify an explicit size',
        description: 'Message to indicate that the specified disk size is smaller than the size of the corresponding disk in the VM image.',
    },
    invalidImagePlanInfoError: {
        id: 'InvalidImagePlanInfoError',
        defaultMessage: 'The Marketplace image or the custom image you have chosen is not supported by Azure Lab Services, please try creating a lab with a different image.',
        description: 'Message to indicate that the Marketplace image or a customer image is missing plan info.',
    },
    invalidAdminUsernameError: {
        id: 'InvalidAdminUsernameError',
        defaultMessage: 'Invalid admin username. Please try creating new lab with valid username',
        description: 'Message to indicate that creating a lab or publishing the template failed due to invalid admin username.',
    },
    labBeingDeletedError: {
        id: 'LabBeingDeletedError',
        defaultMessage: 'Operation failed due to lab being deleted.',
        description: 'Message to indicate that the operation failed due to lab being deleted.',
    },
    imageUnavailableError: {
        id: 'ImageUnavailableError',
        defaultMessage: 'The Marketplace image or the custom image you have chosen is not supported by Azure Lab Services, please try creating a lab with a different image.',
        description: 'Message to indicate that creating a lab or publishing the template failed due to lab being unavailable.',
    },
    SubnetIsFullError: {
        id: 'SubnetIsFullError',
        defaultMessage: 'Operation failed due to subnet being full. Please make sure the associated subnet have sufficient IP addresses available.',
        description: 'Message to indicate that the operation failed due to subnet not having enough IP addresses.',
    },
});
export const getErrorMessage = (intl, error) => {
    switch (error.failureOperation) {
        case FailureOperation.StopTemplate:
        case FailureOperation.StopEnvironments:
            return getStopErrorMessage(intl, error);
        case FailureOperation.StartTemplate:
        case FailureOperation.StartEnvironments:
            return getStartErrorMessage(intl, error);
        case FailureOperation.ResetPassword:
            return getResetPasswordErrorMessage(intl, error);
        case FailureOperation.PublishTemplate:
            return getPublishErrorMessage(intl, error);
        case FailureOperation.SaveSharedImage:
            return getSaveImageErrorMessage(intl, error);
        case FailureOperation.UpdateTemplate:
            return getUpdateTemplateErrorMessage(intl, error);
        case FailureOperation.GetTemplate:
        case FailureOperation.ListTemplates:
            return getFetchTemplateErrorMessage(intl, error);
        default:
            return getFallbackErrorMessage(intl, error);
    }
};
const getStopErrorMessage = (intl, error) => {
    switch (error.code) {
        case ErrorCode.NotFound:
        case ErrorCode.ResourceNotFound:
        case ErrorCode.ResourceIdNotFound:
            return intl.formatMessage(messages.stopTemplateErrorNotFound);
        case ErrorCode.EnvironmentSettingsHasNoResources:
            return intl.formatMessage(messages.stopTemplateErrorNoResources);
        default:
            const errorMessage = intl.formatMessage(messages.stopTemplateGenericError);
            return getFallbackErrorMessage(intl, error, errorMessage);
    }
};
const getStartErrorMessage = (intl, error) => {
    switch (error.code) {
        case ErrorCode.NotFound:
        case ErrorCode.ResourceNotFound:
        case ErrorCode.ResourceIdNotFound:
            return intl.formatMessage(messages.startTemplateErrorNotFound);
        case ErrorCode.EnvironmentSettingsHasNoResources:
            return intl.formatMessage(messages.startTemplateErrorNoResources);
        default:
            const errorMessage = intl.formatMessage(messages.startTemplateGenericError);
            return getFallbackErrorMessage(intl, error, errorMessage);
    }
};
const getResetPasswordErrorMessage = (intl, error) => {
    switch (error.code) {
        case ErrorCode.NotFound:
        case ErrorCode.ResourceNotFound:
        case ErrorCode.ResourceIdNotFound:
            return intl.formatMessage(messages.resetPasswordTemplateErrorNotFound);
        case ErrorCode.EnvironmentSettingsHasNoResources:
            return intl.formatMessage(messages.resetPasswordTemplateErrorNoResources);
        case ErrorCode.ResourceNotInSucceededState:
            return intl.formatMessage(messages.resetPasswordTemplateErrorResourceNotInSucceededState);
        case ErrorCode.ResourceDoesNotExist:
            return intl.formatMessage(messages.resetPasswordTemplateErrorResourceDoesNotExist);
        case ErrorCode.PasswordNotValid:
            return intl.formatMessage(messages.resetPasswordTemplateInvalidPassword);
        case ErrorCode.VMAgentStatusCommunicationError:
            return intl.formatMessage(messages.resetPasswordVmAgentNoCommunicationError);
        default:
            const errorMessage = intl.formatMessage(messages.resetPasswordTemplateGenericError);
            return getFallbackErrorMessage(intl, error, errorMessage);
    }
};
const getPublishErrorMessage = (intl, error) => {
    switch (error.code) {
        case ErrorCode.NotFound:
        case ErrorCode.ResourceNotFound:
        case ErrorCode.ResourceIdNotFound:
            return intl.formatMessage(messages.publishTemplateErrorNotFound);
        case ErrorCode.EnvironmentSettingsHasNoResources:
            return intl.formatMessage(messages.publishTemplateErrorNoResources);
        case ErrorCode.ResourceNotReady:
        case ErrorCode.ResourceIsNotInValidState:
            return intl.formatMessage(messages.publishTemplateErrorResourceNotReady);
        case ErrorCode.ResourceNotInSucceededState:
            return intl.formatMessage(messages.publishTemplateErrorResourceNotInSucceededState);
        case ErrorCode.SubscriptionNotEnabled:
            return intl.formatMessage(messages.publishTemplateErrorSubscriptionNotEnabled);
        case ErrorCode.TotalRegionalCoreLimit:
            return intl.formatMessage(messages.publishTemplateTotalRegionalCoreLimitError);
        case ErrorCode.InvalidAdminUsername:
            return intl.formatMessage(messages.invalidAdminUsernameError);
        case ErrorCode.LabBeingDeleted:
            return intl.formatMessage(messages.labBeingDeletedError);
        case ErrorCode.ImageUnavailable:
            return intl.formatMessage(messages.imageUnavailableError);
        case ErrorCode.PublishExceedsGpuCoresLimit:
        case ErrorCode.PublishExceedsStandardCoresLimit:
            return error.message;
        case ErrorCode.Arm64Incompatibility:
            return intl.formatMessage(messages.arm64IncompatibilityError);
        case ErrorCode.InvalidDiskSize:
            return intl.formatMessage(messages.invalidDiskSizeError);
        case ErrorCode.InvalidImagePlanInfo:
            return intl.formatMessage(messages.invalidImagePlanInfoError);
        case ErrorCode.DiskSizeLesserThanExisting:
            return intl.formatMessage(messages.publishTemplateErrorDiskSizeLesserThanExisting);
        case ErrorCode.SubnetIsFull:
            return intl.formatMessage(messages.SubnetIsFullError);
        default:
            const errorMessage = intl.formatMessage(messages.publishTemplateGenericError);
            return getFallbackErrorMessage(intl, error, errorMessage);
    }
};
const getSaveImageErrorMessage = (intl, error) => {
    switch (error.code) {
        case ErrorCode.NotFound:
        case ErrorCode.ResourceNotFound:
        case ErrorCode.ResourceIdNotFound:
            return intl.formatMessage(messages.saveImageTemplateErrorNotFound);
        case ErrorCode.OperationAlradyInProgress:
            return intl.formatMessage(messages.saveImageTemplateErrorAlreadyInProgress);
        case ErrorCode.UnexpectedResourceId:
            return intl.formatMessage(messages.saveImageTemplateErrorUnexpectedResourceId);
        case ErrorCode.ResourceNotInSucceededState:
            return intl.formatMessage(messages.saveImageTemplateErrorResourceNotInSucceededState);
        case ErrorCode.InvalidUploadTargetToOverride:
            return intl.formatMessage(messages.saveImageTemplateErrorInvalidUploadTargetToOverride);
        case ErrorCode.ResourceDoesNotExist:
            return intl.formatMessage(messages.saveImageTemplateErrorResourceDoesNotExist);
        case ErrorCode.NoSharedImageGalleriesAttachedToTheLabAccount:
            return intl.formatMessage(messages.saveImageTemplateErrorNoSharedImageGalleriesAttachedToTheLabAccount);
        case ErrorCode.SaveImageRegionMismatch:
            return intl.formatMessage(messages.saveImageTemplateErrorRegionMismatch);
        case ErrorCode.SaveImageAlreadyInProgress:
            return intl.formatMessage(messages.saveImageAlreadyInProgressError);
        default:
            const errorMessage = intl.formatMessage(messages.saveImageTemplateGenericError);
            return getFallbackErrorMessage(intl, error, errorMessage);
    }
};
const getUpdateTemplateErrorMessage = (intl, error) => {
    switch (error.code) {
        case ErrorCode.NotFound:
        case ErrorCode.ResourceNotFound:
        case ErrorCode.ResourceIdNotFound:
            return intl.formatMessage(messages.updateTemplateErrorNotFound);
        case ErrorCode.ResourceInDeletingState:
            return intl.formatMessage(messages.updateTemplateErrorResourceInDeletingState);
        default:
            const errorMessage = intl.formatMessage(messages.updateTemplateGenericError);
            return getFallbackErrorMessage(intl, error, errorMessage);
    }
};
const getFetchTemplateErrorMessage = (intl, error) => {
    switch (error.code) {
        case ErrorCode.NotFound:
        case ErrorCode.ResourceNotFound:
        case ErrorCode.ResourceIdNotFound:
            return intl.formatMessage(messages.getTemplateErrorNotFound);
        default:
            const errorMessage = intl.formatMessage(messages.getTemplateGenericError);
            return getFallbackErrorMessage(intl, error, errorMessage);
    }
};
const getFallbackErrorMessage = (intl, error, genericMessageForOperation) => {
    // for cases where we haven't recorded a code and corresponding message in this client code yet
    trackTrace(`template-error-handling: getFallbackErrorMessage - Unmapped error code: ${error ? error.code : 'Unknown'}`);
    return genericMessageForOperation || intl.formatMessage(commonMessages.genericErrorMessage);
};
const TemplateErrorHandling = {
    getErrorMessage,
};
export default TemplateErrorHandling;
